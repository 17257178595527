import './contentcontainer.css';

function ContentContainer(props) {
  return (
    <div className="ContentContainerBase">
      <div className="ContentContainerInner">{props.children}</div>
    </div>
  );
}

export default ContentContainer;
