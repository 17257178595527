import './splitcontentsection.css';

function SplitContentSection(props) {
  return (
    <div className="SplitContentSection">
      <div className="InnerContentLeft">{props.leftContent}</div>
      <div className="InnerContentRight">{props.rightContent}</div>
    </div>
  );
}

export default SplitContentSection;
