import "./Faq.css";
import MainContentContainer from "../../components/maincontentcontainer";
import ContentContainer from "../../components/contentcontainer";
import ContentHeader from "../../components/contentheader";
import FloralDivider from "../../components/floraldivider";

const faq = [
  {
    question: "What's the vibe?",
    answer: "Think along the lines of 'fancy garden party'. If all goes to plan, we'll be hanging out outside!",
  },
  {
    question: "WHat should I wear?",
    answer: "Keely says that colors are good!",
  },
  {
    question: "Can I bring my child?",
    answer:
      "We understand that many of our friends now have children as part of their families, but we kindly ask for no infants or toddlers since they have the highest potential to disrupt the intimate ceremony we have planned. You've been invited because we want YOU to celebrate with us! It's always a bummer to have to see people get up and leave in the middle of a ceremony because a child won't cooperate.",
  },
];

function Events(props) {
  return (
    <MainContentContainer>
      <ContentContainer>
        <ContentHeader>
          <FloralDivider text={"Info"} />
        </ContentHeader>
        <div className="ContentSection">
          <div className="ContentContainer">
            {faq.map((qa, index) => {
              return (
                <div className="QAContainer" id={`qa-${index}`}>
                  <div className="QAHeader">{qa.question}</div>
                  <div className="QADescription">{qa.answer}</div>
                </div>
              );
            })}
          </div>
        </div>
      </ContentContainer>
    </MainContentContainer>
  );
}

export default Events;
