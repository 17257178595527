import "./RSVP.css";
import SplitContentSection from "../../components/splitcontentsection";
import MainContentContainer from "../../components/maincontentcontainer";
import ContentContainer from "../../components/contentcontainer";
import ContentHeader from "../../components/contentheader";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import FloralDivider from "../../components/floraldivider";

import { useState, useEffect } from "react";

interface TableData {
  id: Number;
  firstName: string;
  lastName: string;
  restrictions: string;
}

function RSVP(props) {
  //const []

  const [rsvpData, setRSVPData] = useState([
    {
      id: 0,
      firstName: "Something Odd",
      lastName: "asdkjasdlkj",
      restrictions: "",
    },
  ]);

  const handleAdd = () => {
    setRSVPData([
      ...rsvpData,
      {
        id: rsvpData.length + 1,
        firstName: "",
        lastName: "",
        restrictions: "",
      },
    ]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rsvpData];
    list[index][name] = value;
    setRSVPData(list);
  };

  useEffect(() => {
    const localStorageValue = window.localStorage.getItem("jkrsvpdata");

    if (localStorageValue) {
      setRSVPData(JSON.parse(localStorageValue));
    }
  }, [rsvpData]);

  return (
    <>
      <MainContentContainer>
        <ContentContainer>
          <ContentHeader>
            <FloralDivider text={`Hello ${props.tokenData.displayName}!`} />
          </ContentHeader>
          <div>
            We're very excited to have you come to our wedding! We kindly request that you fill out the following
            details to better assist us with the planning! If you could include the names of the people that will be
            attending and please write in any dietary considerations it would be much appreciated!
          </div>
          <Button className="AddPersonButton" onClick={() => setRSVPData([...rsvpData, {}])}>
            Add Person
          </Button>
          <Form>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Guest #</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Special Considerations</th>
                  <th>Delete User</th>
                </tr>
              </thead>
              <tbody>
                {rsvpData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <input
                          type="text"
                          name="firstName"
                          value={item.firstName}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="lastName"
                          value={item.lastName}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          id={`considerations${index}`}
                          placeholder=""
                          name="restrictions"
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                      <td>
                        <i
                          className="fa fa-trash"
                          onClick={(event) => {
                            event.stopPropagation();
                            const list = [...rsvpData];
                            list.splice(index, 1);
                            setRSVPData(list);
                          }}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
          <SplitContentSection leftContent={null} rightContent={null}></SplitContentSection>
        </ContentContainer>
      </MainContentContainer>
    </>
  );
}

export default RSVP;
