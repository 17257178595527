import "./Header.css";
import Navigation, { NavigationTargets } from "./Navigation";

function Header(props) {
  let headerLabel;

  if (props.navTarget === NavigationTargets.OURSTORY) {
    headerLabel = (
      <time className="HeaderLabel" dateTime="2023-08-19">
        August 19, 2023
      </time>
    );
  } else {
    let label = "";
    switch (props.navTarget) {
      case NavigationTargets.EVENTS:
        label = "Events & Schedules";
        break;

      case NavigationTargets.GIFTREGISTRY:
        label = "Our Gift Registry";
        break;

      case NavigationTargets.RSVP:
        label = "RSVP For Our Event!";
        break;

      case NavigationTargets.TRAVEL:
        label = "Travel Details";
        break;

      case NavigationTargets.FAQ:
        label = "Questions & Answers";
        break;

      default:
        label = "";
        break;
    }
    headerLabel = <div className="HeaderLabel">{label}</div>;
  }
  return (
    <>
      {props.useSmall && (
        <div className="SmallHeader">
          <div className="SmallNameContainer">
            <div className="SmallName">
              <h1 className="Names">Jason</h1>
            </div>
            <div className="SmallHeaderSpacer">
              <div className="SmallHeaderSpacer">and</div>
            </div>
            <div className="SmallName">
              <h1 className="Names">Keely</h1>
            </div>
          </div>
          <Navigation onNavClick={props.onNavClick} selection={props.navTarget} />
          <div className="SmallDividerTop"></div>
        </div>
      )}
      {!props.useSmall && (
        <div className="HeaderContainer">
          <div className="nameContainer">
            <p className="Names">Jason</p>
            <p className="and">&</p>
            <p className="Names">Keely</p>
          </div>
          <Navigation onNavClick={props.onNavClick} selection={props.navTarget} tokenData={props.tokenData} />
        </div>
      )}
      <div className="HeaderLabelContainer">{headerLabel}</div>
      <div className="SmallDividerTop"></div>
    </>
  );
}

export default Header;
