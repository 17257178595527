import './contentheader.css';

function ContentHeader(props) {
  return (
    <div className="ContentHeaderContainer">
      <h2 className="ContentHeaderText">{props.children}</h2>
    </div>
  );
}

export default ContentHeader;
