import "./OurStory.css";
import MainContentContainer from "../../components/maincontentcontainer";
import ContentContainer from "../../components/contentcontainer";
import ContentHeader from "../../components/contentheader";
import "bootstrap/dist/css/bootstrap.min.css";
import backgroundImage from "../../OurStorySm2.jpg";
import FloralDivider from "../../components/floraldivider";

const chatGPT = (
  <>
    Here's how ChatGPT thinks we met: <br />
    <br />
    Jason had been a frequent flyer on Keely's flights to Hawaii for quite some time. He was a businessman who traveled
    often for work, but he always looked forward to his trips to the tropical paradise.
    <br />
    <br />
    Keely had noticed Jason before, but she had never had the chance to talk to him. That all changed on one particular
    flight when the plane hit some turbulence. Keely had always been calm during turbulence, but she could see that some
    of the passengers were getting nervous.
    <br />
    <br />
    She decided to make an announcement over the intercom, reassuring the passengers that everything was under control
    and that they would be landing safely in no time. Jason was sitting in the aisle seat closest to the front of the
    plane, and he turned to Keely with a grateful smile.
    <br />
    <br />
    "Thank you," he said. "I was starting to get a little worried there."
    <br />
    <br />
    Keely smiled back at him. "No problem. It's all part of the job."
    <br />
    <br />
    After the turbulence had subsided, Jason struck up a conversation with Keely. He told her that he had always loved
    Hawaii and that he had been on countless flights to the island. Keely was delighted to talk to someone who shared
    her love for the place.
    <br />
    <br />
    As they chatted, Keely and Jason found that they had a lot in common. They both loved to travel and explore new
    places. They both had a passion for learning about different cultures and cuisines. And they both had a deep
    appreciation for the natural beauty of Hawaii.
    <br />
    <br />
    By the time the plane landed, Keely and Jason had exchanged numbers and made plans to meet up the next time Jason
    was in town. From there, their friendship blossomed into something more. And as they walked along the sandy beaches
    of Hawaii, hand in hand, they both knew that they had found something special.
  </>
);

const realStory = (
  <>
    <p>
      Keely and Jason met under the most unexpected circumstances. In 2010, Keely was a passionate yoga teacher, known
      for her warm personality and vibrant energy. In those days, she loved nothing more than sharing her passion for
      yoga with her students, helping them find peace and balance in their lives.
    </p>
    <p>
      Jason, a charismatic and adventurous young man, was a close friend of Keely's brother, Ian. One rainy Seattle
      morning, Ian decided to surprise his sister by bringing Jason to one of her yoga classes. Keely was setting up her
      studio when the two friends walked in, their shoes squeaking on the polished wooden floor. Upon seeing her
      brother, Keely broke into a wide smile, but her eyes couldn't help but be drawn to the handsome stranger by his
      side.
    </p>
    <p>
      As the class commenced, Keely led her students through a series of poses, flowing gracefully from one to the next.
      She couldn't help but notice how Jason, despite his obvious inexperience, moved with a natural grace and focus
      that was truly captivating. After the class, Keely and Jason struck up a conversation, their chemistry immediate
      and undeniable.
    </p>
    <p>
      Despite their mutual attraction, fate had other plans for them. Shortly after their first meeting, Keely received
      a job offer in California that she couldn't refuse. With a heavy heart, she packed up her life and moved away from
      Seattle, leaving her family, friends, and a certain yoga novice behind.
    </p>
    <p>
      Years went by, and Keely and Jason lost touch as they pursued their own paths in life. Then, one day, Keely
      received an offer to work for Alaska Airlines, which would allow her to move back to Seattle. Excited to be closer
      to her family and friends, Keely gladly accepted the job offer.
    </p>
    <p>
      Shortly after moving back to Seattle, Keely and Jason reconnected and began talking again. As they caught up on
      each other's lives, they discovered that they were both in need of a roommate. Seeing this as a perfect
      opportunity to spend more time together and rekindle their friendship, they agreed to become roommates.
    </p>
    <p>
      As they settled into their new living arrangement, Keely and Jason found themselves spending more and more time
      together. Their closeness grew, and the once strong friendship blossomed into something more profound and
      meaningful. It wasn't until after they had moved apart, that it became clear to both of them that their feelings
      for each other had evolved into a deep and abiding love.
    </p>
    <p>
      In the years that followed, Keely and Jason would continue to grow and learn from one another, their love a beacon
      of hope and joy that illuminated their lives, proving that sometimes, fate has a way of bringing people together
      when they least expect it.
    </p>
  </>
);

/*
const firstDraft = (
  <>
    <br />
    <br />
    Here's how we actually met:
    <br />
    <br />
    Jason and Keely originally met in 2010 while Keely was visiting her family in Seattle. Jason was roomates with
    Keely's brother, and they first me when she came over to visit. A few months later, Keely moved to the greater
    Seattle area where she taught Bikram Yoga. Jason had never done Bikram Yoga before and was curious to give it a
    shot. His first class was taught by Keely and it was eye opening just how much of a workout the class really was!
    <br />
    <br />
    Keely was only in the Seattle are for a brief amount of time before moving back to California to start her career in
    the aviation industry working for the TSA. Eventualy hiring opportunities opened up at Alaska Airlines, and it
    brought Keely back to Seattle where she became a flight attendant.
    <br />
    <br />
    After moving back to Seattle, Jason & Keely reconnected where they shared an apartment in downtown Seattle. From
    there, their friendship blossomed and turned into a romantic relationship. They lived in a house in West Seattle
    before selling it after the COVID pandemic began and moved south along the Washington/Oregon border. They now have a
    great house in Washougal with awesome pets!
    <br />
    <br />
    They're so excited to start the next phase of their lives together and can't wait to celebrate with you in August!
    <br />
  </>
);
*/

function OurStory() {
  return (
    <>
      <MainContentContainer>
        <ContentContainer>
          <ContentHeader>
            <FloralDivider text={"Our Story"} />
          </ContentHeader>
          <div className="StoryContentContainer">
            <div className="LeftCell" id="cell1">
              <div className="SectionText">{realStory}</div>
            </div>
            <div className="RightCell">
              <img src={backgroundImage} alt="Jason & Keely" className="OurStoryImage" />
            </div>
          </div>
        </ContentContainer>
      </MainContentContainer>
    </>
  );
}

export default OurStory;
