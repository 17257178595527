import './App.css';
import { useState, useEffect } from 'react';
import Header from './Header';
import OurStory from './pages/ourstory/OurStory';
import RSVP from './pages/rsvp/RSVP';
import Events from './pages/events/Events';
import Travel from './pages/travel/Travel';
import Registry from './pages/registry/Registry';
import Faq from './pages/faq/Faq';
import { NavigationTargets } from './Navigation';
import * as jose from 'jose';

const localStorageKey = 'jkrsvp';

function App() {
  const [navigationState, setNavigation] = useState(NavigationTargets.OURSTORY);
  const [tokenData, setTokenData] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenValue = queryParams.get('token');
    const localStorageValue = window.localStorage.getItem(localStorageKey);

    if (tokenValue) {
      const decodeToken = jose.decodeJwt(tokenValue);
      setTokenData(decodeToken);

      if (!localStorageValue) {
        window.localStorage.setItem(localStorageKey, tokenValue);
      }
    } else if (localStorageValue) {
      setTokenData(jose.decodeJwt(localStorageValue));
    }
  }, []);

  return (
    <div className="App">
      <Header onNavClick={(navTarget) => setNavigation(navTarget)} navTarget={navigationState} tokenData={tokenData} />
      {navigationState === NavigationTargets.OURSTORY && <OurStory />}
      {tokenData && navigationState === NavigationTargets.RSVP && <RSVP tokenData={tokenData} />}
      {navigationState === NavigationTargets.EVENTS && <Events />}
      {navigationState === NavigationTargets.TRAVEL && <Travel />}
      {navigationState === NavigationTargets.FAQ && <Faq />}
      {navigationState === NavigationTargets.GIFTREGISTRY && <Registry />}
    </div>
  );
}

export default App;
