import "./Travel.css";
import MainContentContainer from "../../components/maincontentcontainer";
import ContentContainer from "../../components/contentcontainer";
import ContentHeader from "../../components/contentheader";
import "bootstrap/dist/css/bootstrap.min.css";
import FloralDivider from "../../components/floraldivider";

function Travel(props) {
  let hotelInfo = null;

  const residenceInnInfo = (
    <p>
      <a href="https://goo.gl/maps/88BpfMF5XDRaFoZ1A" rel="noreferrer" target="_blank">
        Residence Inn by Marriott Portland Clackamas{" "}
      </a>{" "}
      <br />
      9191 SE Sunnyside Rd
      <br />
      Clackamas, OR 97015
      <br />
      (503)-557-3566
      <br />
    </p>
  );

  const residenceInnDetails = (
    <ul>
      <li>This location is about 1.6 miles away from the venue. Less than 10 minutes away!</li>
      <li>
        Please use{" "}
        <a
          href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1680555293439&key=GRP&app=resvlink"
          target="_blank"
          rel="noreferrer"
        >
          this link
        </a>{" "}
        for your bookings for a discounted rate. You might find a better deal through a 3rd party travel site, but this
        was what they offered us.
      </li>
    </ul>
  );

  const hamptonInnInfo = (
    <p>
      <a href="https://goo.gl/maps/ngM24xABfhtQVBiF9" rel="noreferrer" target="_blank">
        Hampton Inn Portland/Clackamas{" "}
      </a>{" "}
      <br />
      9040 SE Adams St
      <br />
      Clackamas, OR 97015
      <br />
      (503)-655-7900
      <br />
    </p>
  );
  const hamptonInnDetails = (
    <ul>
      <li>This location is about 3.7 miles (9 minutes) away from the venue.</li>
    </ul>
  );

  const courtyardInfo = (
    <p>
      <a href="https://goo.gl/maps/GjutxZ4WVQDayPbh9" rel="noreferrer" target="_blank">
        Courtyard by Marriott Portland Southeast/Clackamas{" "}
      </a>{" "}
      <br />
      9300 SE Sunnybrook Blvd
      <br />
      Clackamas, OR 97015
      <br />
      (503)-652-2900
      <br />
    </p>
  );
  const courtyardDetails = (
    <ul>
      <li>This location is about 1.7 miles (6-7 minutes) away from the venue.</li>
    </ul>
  );

  hotelInfo = (
    <div>
      <ul>
        <li>
          {residenceInnInfo}
          {residenceInnDetails}
        </li>
        <li className="TopPadding">
          {hamptonInnInfo}
          {hamptonInnDetails}
        </li>
        <li className="TopPadding">
          {courtyardInfo}
          {courtyardDetails}
        </li>
      </ul>
    </div>
  );

  return (
    <MainContentContainer>
      <ContentContainer>
        <p>
          Here you can find some helpful travel information like the location of the wedding venue, and some suggested
          hotels. We know the options we listed aren't the cheapest, but there are quite a few hotels in the area that
          are pretty close to the venue, so you might be able to find some better rates through 3rd party travel sites,
          the ones below are places we checked out in person, so we have a pretty good idea of their location with
          respect to the venue.
        </p>
        <ContentHeader>
          <FloralDivider text={"Venue"} />
        </ContentHeader>
        <div className="CenteredContainer">
          <p>
            <a href="https://goo.gl/maps/uTaLrpdX7aVSNizy6" rel="noreferrer" target="_blank">
              The Aerie At Eagle Landing
            </a>
            <br />
            10220 SE Causey Ave
            <br />
            Happy Valley, OR 97086
          </p>
        </div>
        <ContentHeader>
          <FloralDivider text={"Hotels"} />
        </ContentHeader>
        <div className="ContentContainer">
          <div>{hotelInfo}</div>
        </div>
      </ContentContainer>
    </MainContentContainer>
  );
}

export default Travel;
