import "./Events.css";
import MainContentContainer from "../../components/maincontentcontainer";
import ContentContainer from "../../components/contentcontainer";
import ContentHeader from "../../components/contentheader";
import FloralDivider from "../../components/floraldivider";

function Events(props) {
  return (
    <MainContentContainer>
      <ContentContainer>
        <ContentHeader>
          <FloralDivider text={"08/19/2023"} />
        </ContentHeader>
        <div className="ContentSection">
          The event will be held at{" "}
          <a href="https://theaerieateaglelanding.com/" target="_blank" rel="noreferrer">
            The Aerie at Eagle Landing
          </a>{" "}
          in Happy Valley, Oregon!
          <div className="EventCalendar">
            <div className="Event">
              <div className="EventTime">9:00am</div>
              <div className="EventContainer">
                <div className="EventHeader">Guest Arrival & Pre-Ceremony Snacks</div>
                <div className="EventDescription">
                  <span>
                    All guests are welcome to arrive at this time. There will be fresh coffee, mimosas, and hors
                    d'oeuvres to snack on before the ceremony!
                  </span>
                </div>
              </div>
            </div>
            <div className="Event">
              <div className="EventTime">9:45am</div>
              <div className="EventContainer">
                <div className="EventHeader">Wedding Ceremony</div>
                <div className="EventDescription">
                  <span>Please join us outside for the ceremony!</span>
                </div>
              </div>
            </div>
            <div className="Event">
              <div className="EventTime">10:15am</div>
              <div className="EventContainer">
                <div className="EventHeader">Cocktail Hour</div>
                <div className="EventDescription">
                  <span>After the ceremony please enjoy some drinks and snacks before the main meal is served!</span>
                </div>
              </div>
            </div>
            <div className="Event">
              <div className="EventTime">11:15am</div>
              <div className="EventContainer">
                <div className="EventHeader">Brunch!</div>
                <div className="EventDescription">
                  <span>Find your seat, and enjoy some food!</span>
                </div>
              </div>
            </div>
            <div className="Event">
              <div className="EventTime">12:00pm</div>
              <div className="EventContainer">
                <div className="EventHeader">Reception</div>
                <div className="EventDescription">
                  After some tasty food, please join us outside for the reception! We're going to have a variety of lawn
                  games and entertainment where we can all just hang out together and have some fun!
                </div>
              </div>
            </div>
            <div className="Event">
              <div className="EventTime">2:00pm</div>
              <div className="EventContainer">
                <div className="EventHeader">Send Off</div>
                <div className="EventDescription">
                  Please join Jason & Keely for a unique celebratory send off to close out the event!
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
    </MainContentContainer>
  );
}

export default Events;
