import "./Registry.css";
import MainContentContainer from "../../components/maincontentcontainer";
import backgroundImage from "../../GiftRegistrySm.png";
import ContentContainer from "../../components/contentcontainer";

function GiftRegistry(props) {
  return (
    <MainContentContainer>
      <ContentContainer>
        <p>
          If you'd like to give us a celebratory gift, we have consolidated our registry lists and you can find
          everything at:
          <ul>
            <li>
              <a
                href="https://www.myregistry.com/wedding-registry/keely-scott-and-jason-runta-washougal-wa/3748417"
                target="_blank"
                rel="noreferrer"
              >
                myregistry.com
              </a>{" "}
              (password: jkventures)
            </li>
          </ul>
        </p>
        <img className="GiftImage" src={backgroundImage} alt="Toys?!"></img>
      </ContentContainer>
    </MainContentContainer>
  );
}

export default GiftRegistry;
