import "./floraldivider.css";
import dividerImage from "../smdivider.png";

function FloralDivider(props) {
  return (
    <div className="DividerContainer">
      <img src={dividerImage} alt="divider" className="DividerImage" />
      <div>{props.text}</div>
      <img src={dividerImage} alt="divider" className="DividerImage" />
    </div>
  );
}

export default FloralDivider;
