import './Navigation.css';

export const NavigationTargets = {
  OURSTORY: 'ourstory',
  RSVP: 'rsvp',
  GIFTREGISTRY: 'giftregistry',
  EVENTS: 'events',
  TRAVEL: 'travel',
  FAQ: 'faq',
};

function Navigation(props) {
  console.log(props.selection);
  return (
    <ul className="Navigation">
      <li
        className={props.selection === NavigationTargets.OURSTORY ? 'NavItemSelected' : 'NavItem'}
        onClick={() => props.onNavClick(NavigationTargets.OURSTORY)}
      >
        Home
      </li>
      {props.tokenData !== null && (
        <li
          className={props.selection === NavigationTargets.RSVP ? 'NavItemSelected' : 'NavItem'}
          onClick={() => props.onNavClick(NavigationTargets.RSVP)}
        >
          RSVP
        </li>
      )}
      <li
        className={props.selection === NavigationTargets.EVENTS ? 'NavItemSelected' : 'NavItem'}
        onClick={() => props.onNavClick(NavigationTargets.EVENTS)}
      >
        Events
      </li>
      <li
        className={props.selection === NavigationTargets.TRAVEL ? 'NavItemSelected' : 'NavItem'}
        onClick={() => props.onNavClick(NavigationTargets.TRAVEL)}
      >
        Travel
      </li>
      <li
        className={props.selection === NavigationTargets.FAQ ? 'NavItemSelected' : 'NavItem'}
        onClick={() => props.onNavClick(NavigationTargets.FAQ)}
      >
        FAQ
      </li>
      <li
        className={props.selection === NavigationTargets.GIFTREGISTRY ? 'NavItemSelected' : 'NavItem'}
        onClick={() => props.onNavClick(NavigationTargets.GIFTREGISTRY)}
      >
        Gift Registry
      </li>
    </ul>
  );
}

export default Navigation;
